import React, {
  ChangeEventHandler,
  Component,
  createRef,
  Fragment,
} from 'react';
import classNames from 'classnames';
import { BadgeList } from '@wix/members-badge-lib';
import { RoleId } from '@wix/members-domain-ts';

import {
  BaseComponentProps,
  AdditionalActionId,
  DataHook,
  ElementId,
  Position,
  ProfileLayout,
} from '../../../../../types';
import { titleMaxLength } from '../../../../../constants/limits';
import { getCoverUrl } from '../../../../../services/cover-utils';
import { maybeOpenContactsWindow } from '../../../../../services/navigation';
import {
  absorbArguments,
  forwardResizedImage,
  forwardTargetValue,
} from '../../../../../services/event-handler';
import { getElementId } from '../../../../../services/html-attributes';
import LoaderOverlay from '../../../../../common/components/LoaderOverlay';
import Avatar from '../../../../../common/components/Avatar';
import MemberName from '../../../../../common/components/MemberName';
import LimitCounter from '../../../../../common/components/LimitCounter';
import RoleIcons from '../../../../../common/components/RoleIcons';
import Blocked from '../../../../../common/components/Blocked';
import Button, {
  ButtonClickHandler,
} from '../../../../../common/components/Button';
import FollowOrChatButton from '../../../../../common/components/FollowOrChatButton';
import MemberMoreButton from '../../../../../common/components/MemberMoreButton';
import styles from './DesktopNonResponsiveWidget.scss';

interface ButtonProps {
  dataHook?: DataHook;
  title: string;
  isSecondary: boolean;
  onClick: ButtonClickHandler;
}

enum BREAKPOINTS {
  NARROW = 659,
  WIDE = 840,
}

class DesktopNonResponsiveWidget extends Component<BaseComponentProps> {
  private memberNameInputRef = createRef<HTMLInputElement>();

  componentDidUpdate({ profilePage }: BaseComponentProps) {
    const { isEditing } = this.props.profilePage;
    const memberNameInput = this.memberNameInputRef.current;

    if (profilePage.isEditing !== isEditing && memberNameInput) {
      memberNameInput.focus();
    }
  }

  render = () => {
    const { site, profilePage, globalSettings, computed, host } = this.props;
    const { showFollowers } = globalSettings;
    const { isDesignPreview, inCommunity, showCover } = computed;
    const { isSocial } = site;
    const isFollowersVisible = showFollowers && inCommunity && isSocial;

    const containerClassName = classNames({
      [styles.profile]: true,
      [styles.designPreview]: isDesignPreview || profilePage.isEditing,
      [styles.withCover]: showCover,
      [styles.editMode]: profilePage.isEditing,
      [styles.nonSocial]: !site.isSocial,
      [this.getWidgetSizeClass(host?.dimensions)]: true,
    });

    return (
      <div data-hook={DataHook.VerticalWidget}>
        <div className={styles.box}>
          <div className={styles.border} />
          <div className={containerClassName}>
            {this.props.profilePage.isSaving && this.renderLoader()}
            {showCover && this.renderCover()}
            <div className={styles.layout}>
              {this.renderPhoto()}
              <div className={styles.content}>
                <div className={styles.memberDetails}>
                  {this.renderMemberDetails()}
                  {isFollowersVisible && this.renderFollowers()}
                </div>
                {this.renderButtons()}
                {this.renderActions()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  private readonly getWidgetSizeClass = (dimensions?: {
    width: number;
    height: number;
  }) => {
    if (!dimensions?.width) {
      return styles.fullWidth;
    }

    if (dimensions.width <= BREAKPOINTS.NARROW) {
      return styles.small;
    }

    if (dimensions.width < BREAKPOINTS.WIDE) {
      return styles.medium;
    }

    return styles.large;
  };

  private readonly getCoverPhoto = () => {
    const { member, profilePage, globalSettings } = this.props;
    const { editCover } = profilePage;

    const img =
      (editCover && editCover.file) ||
      getCoverUrl({
        member,
        editCover,
        defaultCoverUrl: globalSettings.defaultProfileCoverUrl,
        profileLayout: ProfileLayout.Card,
        isMobile: false,
      });

    return img ? { backgroundImage: `url('${img}')` } : null;
  };

  private readonly renderLoader = () => (
    <LoaderOverlay className={styles.loader} />
  );

  private readonly renderCover = () => {
    const { compId, profilePage, handlers, computed, t } = this.props;
    const coverPhotoInputId = getElementId(compId, ElementId.CoverPhotoInput);
    const coverPhotoStyle = this.getCoverPhoto() || {};

    const handleCoverUpload: ChangeEventHandler<HTMLInputElement> = (event) => {
      handlers.enterCoverRepositionMode();
      forwardResizedImage(handlers.setEditCover)(event);
    };

    return (
      <div data-hook={DataHook.CoverWrapper} className={styles.coverWrapper}>
        <div className={styles.coverBorderMargin}>
          <div
            data-hook={DataHook.HighQualityCover}
            className={styles.cover}
            style={coverPhotoStyle}
          >
            {!coverPhotoStyle && (
              <div
                className={classNames(styles.overlay, styles.coverOverlay)}
              />
            )}
            <div className={classNames(styles.overlay)} />
            {profilePage.isCoverLoading && this.renderLoader()}
            {computed.canEdit && profilePage.isEditing && (
              <div className={styles.coverPhotoContainer}>
                <input
                  id={coverPhotoInputId}
                  className={styles.uploadInput}
                  type="file"
                  accept="image/*"
                  aria-label={t('profile-widget.change-aria-label-photo')}
                  onChange={handleCoverUpload}
                />
                <label
                  htmlFor={coverPhotoInputId}
                  className={styles.uploadLabel}
                />
                <div className={styles.coverPhotoChange}>
                  <p className={styles.uploadText}>
                    {t('profile-widget.change-photo')}
                  </p>
                  <div className={styles.coverPhotoIco} />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  private readonly renderPhoto = () => {
    const { compId, member, profilePage, computed, handlers, t } = this.props;
    const photoInputId = getElementId(compId, ElementId.ProfilePhotoInput);

    const handlePhotoUpload = forwardResizedImage(handlers.setEditPicture);

    return (
      <Avatar
        dataHook={DataHook.ProfilePhoto}
        className={styles.profilePhoto}
        layoutType={computed.pictureStyle}
        url={profilePage.editPicture?.file ?? member.picture}
        name={null}
      >
        <div className={styles.overlay} />
        {computed.canEdit && profilePage.isEditing && (
          <div className={styles.profilePhotoIco}>
            <input
              id={photoInputId}
              className={styles.uploadInput}
              type="file"
              accept="image/*"
              aria-label={t('profile-widget.change-profile-photo')}
              onChange={handlePhotoUpload}
            />
            <label htmlFor={photoInputId} className={styles.uploadLabel} />
          </div>
        )}
      </Avatar>
    );
  };

  // TODO: Clarify if we need to have a resize detector here if widget is fixed width anyway.
  private readonly renderBadges = () => {
    const { host, site, computed, handlers } = this.props;
    const { navigateToViewedMember } = handlers;

    const width = host?.dimensions?.width ?? 0;
    const isIconOnly = computed.badgesSettings.layout === 'icon-only';
    const maxRows = isIconOnly || width > BREAKPOINTS.NARROW ? 1 : 3;
    const align = width > BREAKPOINTS.NARROW ? 'left' : 'center';

    return (
      <div className={styles.badgesContainer}>
        <BadgeList
          {...computed.badgesSettings}
          align={align}
          maxRows={maxRows}
          onClickMore={site.isSocial ? navigateToViewedMember : undefined}
          onBadgeClick={site.isSocial ? navigateToViewedMember : undefined}
        />
      </div>
    );
  };

  private readonly renderMemberDetails = () => {
    const { member, rolesMap, profilePage, site, handlers, t } = this.props;
    const { badgesSettings, showTitle, showAsBlocked } = this.props.computed;
    const name = profilePage.editName ?? member.name;
    const hasBadges = badgesSettings.badges.length > 0;

    const handleInputChange = forwardTargetValue(handlers.setEditName);

    return (
      <div data-hook={DataHook.MemberName} className={styles.nameBox}>
        <div className={styles.nameWrapper}>
          {profilePage.isEditing && (
            <div className={styles.nameBoxText}>
              <input
                data-hook={DataHook.MemberNameInput}
                ref={this.memberNameInputRef}
                className={styles.nameBoxInput}
                defaultValue={name}
                maxLength={50}
                size={50}
                onChange={handleInputChange}
              />
            </div>
          )}
          {!profilePage.isEditing && (
            <MemberName
              name={name}
              className={styles.nameBoxText}
              tooltipClass={styles.tooltipWrapper}
            />
          )}
          <div className={styles.inputBorder} />
        </div>
        {site.isSocial && (
          <RoleIcons
            containerClassName={styles.roleIconsContainer}
            iconClassName={styles.roleIcon}
            roles={member.roles}
            rolesMap={rolesMap}
            withNames={true}
            t={t}
          />
        )}
        {showTitle && this.renderTitle()}
        {site.isSocial && showAsBlocked && (
          <Blocked className={styles.blocked} t={t} />
        )}
        {hasBadges && this.renderBadges()}
      </div>
    );
  };

  private readonly renderTitle = () => {
    const { member, profilePage, handlers, t } = this.props;
    const { isEditing, editTitle } = profilePage;
    const title = editTitle ?? member.title;
    const handleTitleChange = forwardTargetValue(handlers.setEditTitle);

    return (
      <Fragment>
        {isEditing && (
          <div className={styles.titleWrapper}>
            <textarea
              className={classNames(styles.titleTextarea, 'has-custom-focus')}
              maxLength={titleMaxLength}
              placeholder={t('profile-widget.title-placeholder')}
              defaultValue={title ?? ''}
              onChange={handleTitleChange}
            />
            <LimitCounter
              className={styles.limitCounter}
              limit={titleMaxLength}
              length={title?.length ?? 0}
            />
          </div>
        )}
        {!isEditing && title && (
          <div className={styles.titleWrapper}>
            <p className={classNames(styles.title)}>{title}</p>
          </div>
        )}
      </Fragment>
    );
  };

  private readonly renderFollowers = () => {
    const { member, handlers, t, computed } = this.props;
    const { followersInstalled } = computed;

    const handleFollowersBoxClick = absorbArguments(() => {
      handlers.navigateToViewedMemberFFPage(true);
    });

    const handleFollowingBoxClick = absorbArguments(() => {
      handlers.navigateToViewedMemberFFPage(false);
    });

    return (
      <div
        data-hook={DataHook.FollowersFollowing}
        className={styles.numbersBox}
      >
        <div
          data-hook={DataHook.Followers}
          className={styles.followersBoxContainer}
        >
          <Button
            className={classNames(styles.numbersBoxFollowers, {
              [styles.disabled]: !followersInstalled,
            })}
            onClick={handleFollowersBoxClick}
            isDisabled={!followersInstalled}
          >
            <span>{member.followerCount}</span>
            <div className={styles.numbersBoxText}>
              {t('profile-widget.followers')}
            </div>
          </Button>
        </div>
        <div className={styles.divider} />
        <div data-hook={DataHook.Following}>
          <Button
            className={classNames(styles.numbersBoxFollowing, {
              [styles.disabled]: !followersInstalled,
            })}
            onClick={handleFollowingBoxClick}
            isDisabled={!followersInstalled}
          >
            <span>{member.followingCount}</span>
            <div className={styles.numbersBoxText}>
              {t('profile-widget.following')}
            </div>
          </Button>
        </div>
      </div>
    );
  };

  private readonly renderFollowOrChatButton = () => {
    const { member, profilePage, computed, handlers, t } = this.props;
    const handleFollowButtonClick = absorbArguments(handlers.followOrUnfollow);
    const handleChatButtonClick = absorbArguments(handlers.openChat);

    return (
      <FollowOrChatButton
        dataHook={DataHook.FollowCTA}
        size="medium"
        followText={t('profile-widget.follow')}
        unfollowText={t('profile-widget.unfollow')}
        chatText={t('profile-widget.message')}
        isFollowing={member.isSubscribed}
        isDisabled={profilePage.isProfilePreview}
        shouldShowChatButton={computed.allowChat}
        onFFClick={handleFollowButtonClick}
        onChatClick={handleChatButtonClick}
      />
    );
  };

  private readonly renderButton = ({
    dataHook,
    title,
    isSecondary,
    onClick,
  }: ButtonProps) => {
    const baseClassName = classNames(
      styles.mainButtonElement,
      styles.truncateText,
    );
    const buttonClassName = isSecondary
      ? classNames(baseClassName, styles.buttonSecondary)
      : classNames(baseClassName, styles.buttonPrimary);

    return (
      <Button dataHook={dataHook} className={buttonClassName} onClick={onClick}>
        {title}
      </Button>
    );
  };

  private readonly renderSocialButtons = () => {
    const { computed, handlers, t } = this.props;
    const {
      inCommunity,
      isInProfilePage,
      showEditProfileCTA,
      showViewPublicProfileCTA,
    } = computed;
    const {
      executeRoleAction,
      toggleIsEditingProfile,
      togglePublicProfilePreview,
    } = handlers;

    const handleEditButtonClick = absorbArguments(toggleIsEditingProfile);
    const handleViewPublicProfileButtonClick = () => {
      togglePublicProfilePreview();
    };
    const handleCommunityButtonClick = () => {
      executeRoleAction(RoleId.JOIN_COMMUNITY);
    };

    if (!inCommunity && isInProfilePage) {
      return (
        <div className={styles.buttonsBox}>
          {this.renderButton({
            dataHook: DataHook.JoinCommunityCTA,
            title: t('MemberRoles.action_set.community'),
            isSecondary: true,
            onClick: handleCommunityButtonClick,
          })}
        </div>
      );
    }

    return showEditProfileCTA || showViewPublicProfileCTA ? (
      <div className={styles.buttonsBox}>
        <div className={styles.mainButton}>
          {showEditProfileCTA && (
            <Fragment>
              {this.renderButton({
                dataHook: DataHook.EditProfileCTA,
                title: t('profile-widget.edit'),
                isSecondary: true,
                onClick: handleEditButtonClick,
              })}
            </Fragment>
          )}
          {showViewPublicProfileCTA && (
            <Fragment>
              {this.renderButton({
                dataHook: DataHook.ViewPubLicProfileCTA,
                title: t('profile-widget.view-public-profile'),
                isSecondary: true,
                onClick: handleViewPublicProfileButtonClick,
              })}
            </Fragment>
          )}
        </div>
      </div>
    ) : null;
  };

  private readonly renderButtons = () => {
    const { profilePage, site, computed, handlers, t } = this.props;
    const { isSocial } = site;
    const { isEditing, isProfilePreview } = profilePage;
    const { canEdit, isDesignPreview } = computed;

    if (isEditing || isDesignPreview) {
      const { stopEditingProfile, saveProfile } = handlers;
      const handleCancelButtonClick = absorbArguments(stopEditingProfile);
      const handleSaveButtonClick = absorbArguments(saveProfile);

      return (
        <div className={styles.buttonsBox}>
          {this.renderButton({
            title: t('profile-widget.cancel'),
            isSecondary: true,
            onClick: handleCancelButtonClick,
          })}
          {this.renderButton({
            dataHook: DataHook.SaveProfileCTA,
            title: t('profile-widget.save'),
            isSecondary: false,
            onClick: handleSaveButtonClick,
          })}
        </div>
      );
    }

    if (canEdit && isSocial && !isProfilePreview) {
      return this.renderSocialButtons();
    }

    return !canEdit || isProfilePreview ? (
      <div className={styles.buttonsBox}>
        <div className={styles.mainButton}>
          {this.renderFollowOrChatButton()}
        </div>
      </div>
    ) : null;
  };

  private readonly renderActions = () => {
    const { member, isMobile, isRTL, computed, handlers, t } = this.props;
    const { isProfilePreview } = this.props.profilePage;
    const { additionalActions, rolesActions, isResponsiveEditor } = computed;
    const { uid } = member;

    if (rolesActions.length === 0 && additionalActions.length === 0) {
      return;
    }

    const handleRoleAction = (roleId: RoleId) => {
      if (roleId === RoleId.CONTACT_PAGE) {
        const { metaSiteId } = this.props;
        return maybeOpenContactsWindow(metaSiteId, uid, isResponsiveEditor);
      }

      handlers.executeRoleAction(roleId);
    };

    return (
      <MemberMoreButton
        containerClass={styles.moreActions}
        additionalActions={additionalActions}
        rolesActions={rolesActions}
        position={Position.Bottom}
        alignment={Position.Right}
        isRtlLocale={isRTL}
        isMobile={isMobile}
        isDisabled={isProfilePreview}
        dynamicPositioning={false}
        t={t}
        onAdditionalAction={handlers.executeAdditionalAction}
        onRoleAction={handleRoleAction}
      />
    );
  };
}

export default DesktopNonResponsiveWidget;
